import React, { FunctionComponent, ReactElement } from "react";
import { ThemeProvider } from "styled-components";
import GlobalStyle from "../styles/GlobalStyle";
import ThemeStyle from "../styles/ThemeStyle";
import Header from "../sections/Header/Header";
import Seo from "../components/Seo/Seo";
import Navigation from "../components/Navigation/Navigation";
import Footer from "../sections/Footer/Footer";
import "../fonts/fonts.css";
import { ParallaxProvider } from "react-scroll-parallax";

interface IMainLayout {
  children: ReactElement | ReactElement[];
}

const MainLayout: FunctionComponent<IMainLayout> = ({ children }) => {
  return (
    <ThemeProvider theme={ThemeStyle}>
      <GlobalStyle />
      <Seo />
      <Navigation />
      <ParallaxProvider>
        <Header />
      </ParallaxProvider>
      <main className="main-content">{children}</main>
      <Footer />
    </ThemeProvider>
  );
};

export default MainLayout;
