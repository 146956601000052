import React from "react";
import * as Styled from "./Nav.styled";
import DHubLogo from "../../Logo/DHubLogo";
import ButtonLink from "../../Button/ButtonLink";
import BayerLogo from "../../Logo/BayerLogo";

const Nav: React.FunctionComponent = () => {
  return (
    <Styled.Container>
      <Styled.DHubWrapper>
        <DHubLogo />
      </Styled.DHubWrapper>
      <Styled.LinksWrapper>
        <Styled.Link href="#wizards">#Techwizards</Styled.Link>
        <Styled.Link href="#about">About DHub</Styled.Link>
        <Styled.Link href="#values">Our values</Styled.Link>
        <Styled.Link
          href="https://talent.bayer.com/careers?location=Warszawa%2CMazowieckie%2CPoland&pid=562949956246675&domain=bayer.com&sort_by=relevance&triggerGoButton=false&triggerGoButton=true"
          target={"_blank"}
        >
          Job offers
        </Styled.Link>
      </Styled.LinksWrapper>
      <Styled.Decoration />
      <ButtonLink text="Apply now!" link={"#form"} color="lime" />
      <Styled.BayerWrapper>
        <BayerLogo />
      </Styled.BayerWrapper>
    </Styled.Container>
  );
};

export default Nav;
