import React from "react";
import * as Styled from "./components/Nav.styled";
import NavAnimated from "./components/NavAnimated";
import NavStatic from "./components/NavStatic";

interface NavProps {}

const Navigation: React.FunctionComponent<NavProps> = () => {
  const [animated, setAnimated] = React.useState<boolean>(false);

  const handleScroll = React.useCallback(() => {
    if (window.scrollY > 500) {
      setAnimated(true);
    } else {
      setAnimated(false);
    }
  }, []);

  React.useEffect(() => {
    if (window === undefined) {
      return;
    }
    window.addEventListener("scroll", handleScroll);
  }, []);

  return (
    <Styled.Nav>
      <NavStatic />
      {/*<NavAnimated isVisible={animated} />*/}
    </Styled.Nav>
  );
};

export default Navigation;
