import styled, { css } from "styled-components";
import ThemeStyle from "../../styles/ThemeStyle";
import { AppContainer, mediaDown } from "../../styles/Mixins";
import TextBackground from "../../images/Header/wizard-bg.jpg";
import TextShadow from "../../images/Header/wizard-bg-shadow.png";
import Background from "../../images/Header/background.jpg";
import BackgroundTablet from "../../images/Header/background-tablet.jpg";
import BackgroundMobile from "../../images/Header/background-mobile.jpg";

export const Header = styled.header`
  background: ${ThemeStyle.colors.black} url(${Background}) top / cover
    no-repeat;
  position: relative;
  background-position-y: 50%;
  background-size: 110%;
  height: 100%;
  padding-bottom: 12rem;
  overflow: hidden;

  ${mediaDown(
    ThemeStyle.breakpoints.lg,
    css`
      padding-bottom: 10rem;
      background: ${ThemeStyle.colors.black} url(${BackgroundTablet}) center /
        cover no-repeat;
    `
  )}
  ${mediaDown(
    ThemeStyle.breakpoints.sm,
    css`
      background: ${ThemeStyle.colors.black} url(${BackgroundMobile}) center /
        cover no-repeat;
    `
  )};
`;

const HeaderMask = css`
  background-color: ${ThemeStyle.colors.black};
  position: absolute;
  left: 0;
  width: 100%;
  height: 20vh;
  filter: blur(5rem);
  -webkit-filter: blur(5rem);
  -ms-filter: blur(5rem);
  z-index: 1;
`;

export const HeaderMaskTop = styled.div`
  ${HeaderMask};
  top: 0;
  height: 15vh;
  ${mediaDown(
    ThemeStyle.breakpoints.ultra,
    css`
      height: 20vh;
    `
  )};
  ${mediaDown(
    ThemeStyle.breakpoints.sm,
    css`
      height: 10vh;
    `
  )};
`;

export const HeaderMaskBottom = styled.div`
  ${HeaderMask};
  bottom: 0;
  height: 25vh;
  filter: blur(8rem);
  -webkit-filter: blur(8rem);
  -ms-filter: blur(8rem);
  ${mediaDown(
    ThemeStyle.breakpoints.sm,
    css`
      height: 60vh;
      filter: blur(6rem);
      -webkit-filter: blur(6rem);
      -ms-filter: blur(6rem);
    `
  )};
`;

export const People = styled.img`
  display: none;
  ${mediaDown(
    ThemeStyle.breakpoints.lg,
    css`
      display: block;
      position: absolute;
      bottom: 10%;
      left: 0;
      right: 0;
      transform: scale(1.2);
    `
  )}
`;

export const Container = styled.div`
  ${AppContainer};
  position: relative;
  height: 100%;
  min-height: 100vh;
  flex-flow: column;
  justify-content: flex-end;
  align-items: center;
  z-index: 2;
  ${mediaDown(
    ThemeStyle.breakpoints.lg,
    css`
      padding-bottom: 24rem;
    `
  )};
  ${mediaDown(
    ThemeStyle.breakpoints.lg,
    css`
      padding-bottom: 18rem;
    `
  )};
`;
export const TitleWrapper = styled.div`
  position: relative;
  z-index: 1;
`;

export const TitleMask = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 100%;
  width: 100%;
  background: url("${TextShadow}");
  background-size: cover;
  background-position: center;
  z-index: 1;
  filter: blur(6px);
  -webkit-filter: blur(6px);
  -ms-filter: blur(6px);
`;

export const Title = styled.h1`
  position: relative;
  font-family: ${ThemeStyle.fonts.second};
  line-height: 1;
  padding: 0;
  font-size: 20rem;
  font-weight: ${ThemeStyle.fontWeight.light};
  color: transparent;
  text-transform: uppercase;
  text-align: center;
  background-image: url(${TextBackground});
  background-size: cover;
  background-clip: text;
  -webkit-background-clip: text;
  z-index: 2;

  strong {
    font-weight: ${ThemeStyle.fontWeight.bold};
  }

  ${mediaDown(
    ThemeStyle.breakpoints.xxxl,
    css`
      font-size: 18rem;
    `
  )}
  ${mediaDown(
    ThemeStyle.breakpoints.xxl,
    css`
      font-size: 16rem;
    `
  )}
  ${mediaDown(
    "900px",
    css`
      font-size: 14rem;
    `
  )}
  ${mediaDown(
    ThemeStyle.breakpoints.md,
    css`
      font-size: 12rem;
    `
  )}
  ${mediaDown(
    ThemeStyle.breakpoints.sm,
    css`
      font-size: 18vw;
    `
  )}
`;

export const TextSmall = styled.p`
  font-size: 3.15rem;
  color: ${ThemeStyle.colors.white};
  font-weight: ${ThemeStyle.fontWeight.semiBold};
  text-align: center;
  margin: 0;
  transform: translateY(-2rem);
  z-index: 2;
  br {
    display: none;
  }

  strong {
    font-weight: ${ThemeStyle.fontWeight.bold};
  }
  ${mediaDown(
    ThemeStyle.breakpoints.xxxl,
    css`
      font-size: 2.8rem;
    `
  )}
  ${mediaDown(
    ThemeStyle.breakpoints.xxl,
    css`
      font-size: 2.5rem;
      transform: translateY(-1.7rem);
    `
  )}
  ${mediaDown(
    ThemeStyle.breakpoints.md,
    css`
      font-size: 1.9rem;
      transform: translateY(-1rem);
    `
  )}
  ${mediaDown(
    ThemeStyle.breakpoints.sm,
    css`
      font-size: 4vw;
      transform: translateY(-0.5rem);
      br {
        display: block;
      }
    `
  )}
`;

export const Text = styled.p`
  font-size: 2.6rem;
  color: ${ThemeStyle.colors.white};
  text-align: center;
  font-weight: ${ThemeStyle.fontWeight.light};
  margin-bottom: 4rem;
  text-transform: uppercase;
  br {
    display: none;
  }

  a {
    position: relative;

    &::before {
      content: "";
      position: absolute;
      bottom: -2px;
      left: 50%;
      height: 2px;
      width: 100%;
      transform: translateX(-50%);
      background-color: ${ThemeStyle.colors.white};
      transition: ${ThemeStyle.transition};
      transition-property: background-color, width;
    }

    &:hover {
      &::before {
        background-color: ${ThemeStyle.colors.lime};
        width: 50%;
      }
    }
  }
  ${mediaDown(
    ThemeStyle.breakpoints.xxxl,
    css`
      margin: 0 0 2rem;
      font-size: 2.2rem;
    `
  )}
  ${mediaDown(
    ThemeStyle.breakpoints.xxl,
    css`
      font-size: 2rem;
    `
  )}
  ${mediaDown(
    ThemeStyle.breakpoints.md,
    css`
      font-size: 1.6rem;
    `
  )}
  ${mediaDown(
    ThemeStyle.breakpoints.sm,
    css`
      font-size: 3.5vw;
      margin: 2rem 0;
      br {
        display: block;
      }
    `
  )}
`;
