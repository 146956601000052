import React from "react";
import * as Styled from "./Nav.styled";
import Nav from "./Nav";

const NavStatic: React.FunctionComponent = () => {
  return (
    <Styled.NavStatic>
      <Nav />
    </Styled.NavStatic>
  );
};

export default NavStatic;
