import React, { FunctionComponent, useEffect, useState, useRef } from "react";
import * as Styled from "./Header.styled";
import Lines from "../../components/Lines/Lines";
import Image from "../../images/Header/people-mobile.png";
// import PersonLeft from "./component/PersonLeft";
// import PersonRight from "./component/PersonRight";

const Header: FunctionComponent = () => {
  const header = useRef(null);
  const [isMobile, setMobile] = useState(false);
  // const [parallaxSpeed, setParallaxSpeed] = useState(30);

  useEffect(() => {
    if (typeof window !== "undefined") {
      setMobile(window.innerWidth <= 560);
    }
  }, []);

  // useEffect(() => {
  //   if (isMobile) {
  //     setParallaxSpeed(10);
  //   }
  // }, [isMobile]);

  return (
    <Styled.Header ref={header}>
      <Styled.HeaderMaskTop />

      {/*<PersonLeft parallaxSpeed={parallaxSpeed} />*/}
      {/*<PersonRight parallaxSpeed={parallaxSpeed} />*/}

      <Styled.Container>
        <Styled.TitleWrapper>
          <Styled.People
            alt={"Techwizards"}
            placeholder="blurred"
            src={Image}
          />
          <Styled.Title>
            <strong>GO BIG, FLY HIGH</strong>
          </Styled.Title>
          <Styled.TitleMask />
        </Styled.TitleWrapper>
        <Styled.TextSmall>
          DIGITAL HUB WARSAW OFFERS BOTH. <br />
          AT WORK & AFTER WORK.
        </Styled.TextSmall>
        <Styled.Text>WE ARE #TECHWIZARDS!{"  "}</Styled.Text>
        <Lines />
      </Styled.Container>

      <Styled.HeaderMaskBottom />
    </Styled.Header>
  );
};

export default Header;
